/* src/index.css */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

footer {
  background-color: #3f51b5;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.card-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  padding: 1rem;
}

.header {
  background-color: #3f51b5;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 1rem;
  color: #666;
}

.card-fee {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
}
